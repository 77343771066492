import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { ref } from 'vue'
import axios from 'axios'
import { useLocalStorage } from '@vueuse/core'
import { useCategoryStore } from './CategoryStore'
import { useRouter } from 'vue-router'

export const useAuthStore = defineStore('auth', () => {
  const isLoggedIn = ref(false)
  const forcePasswordChange = ref(false)
  const jwt = useLocalStorage('token')
  const username = useLocalStorage('username')
  const displayName = useLocalStorage('displayName')
  const expiration = ref(0)

  const router = useRouter()
  const categoryStore = useCategoryStore()

  async function loginUser(login, password) {
    username.value = login
    await axios.post('/api/auth/login', { Username: login, Password: password })
      .then(function (response) {
        jwt.value = response.data.token
        const content = jwtDecode(jwt.value)
        expiration.value = content.exp
        displayName.value = content.display_name
        if (expiration.value > Math.floor(Date.now() / 1000)) {
          isLoggedIn.value = true
        }
      })
      .catch(function (error) {
        jwt.value = ''
        isLoggedIn.value = false
        categoryStore.tabs = []
        if (error.response.status === 423) {
          forcePasswordChange.value = true
        }
      })

    return isLoggedIn.value
  }

  async function changePassword(login, oldPassword, newPassword) {
    username.value = login
    await axios.post('/api/auth/user/pw', { Username: login, OldPassword: oldPassword, NewPassword: newPassword })
      .then(function (response) {
        jwt.value = response.data.token
        const content = jwtDecode(jwt.value)
        expiration.value = content.exp
        displayName.value = content.display_name
        isLoggedIn.value = true
      })
      .catch(function () {
        jwt.value = ''
        isLoggedIn.value = false
        categoryStore.tabs = []
      })

    return isLoggedIn.value
  }

  async function getLoggedIn() {
    try {
      const content = jwtDecode(jwt.value)
      expiration.value = content.exp
      displayName.value = content.display_name

      if (expiration.value > Math.floor(Date.now() / 1000) + 60) {
        isLoggedIn.value = true
        await categoryStore.initializeCategories()
      } else {
        await axios.post('/api/auth/refresh', { token: jwt.value })
          .then(function (response) {
            console.log('Token Refreshed')
            jwt.value = response.data.token
            const content = jwtDecode(jwt.value)
            expiration.value = content.exp
            displayName.value = content.display_name
            isLoggedIn.value = true
          })
          .catch(function (err) {
            console.log(err)
            jwt.value = ''
            isLoggedIn.value = false
            categoryStore.tabs = []
          })
      }
    } catch {
      jwt.value = ''
      isLoggedIn.value = false
      categoryStore.tabs = []
    }
    return isLoggedIn.value
  }

  function logout() {
    jwt.value = ''
    isLoggedIn.value = false
    categoryStore.tabs = []
    router.push({ name: 'login' })
  }

  function getJWT() {
    return jwt.value
  }

  async function getUsers() {
    let result = []
    await axios.get('/api/users', {
      headers: {
        Authorization: 'Bearer ' + jwt.value
      }
    })
      .then(function (response) {
        result = response.data
      })
    return result
  }

  return { forcePasswordChange, username, expiration, isLoggedIn, displayName, loginUser, getLoggedIn, getJWT, changePassword, logout, getUsers }
})
