
import 'quasar/dist/quasar.css'
// import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import iconSet from 'quasar/icon-set/fontawesome-v6'
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
  },
  plugins: {
    Notify,
    Loading
  },
  iconSet
}
