<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated class="glossy bg-cyan">
      <q-tabs class="text-white shadow-2">
        <q-route-tab name="home" icon="fas fa-house" label="Home" to="/page" />
        <q-route-tab v-for="tab in categoryStore.tabs" :key="tab.Name" :icon="tab.Icon" :label="tab.Name"
          :to="'/category/' + tab.Id" />
        <q-route-tab name="settings" icon="fas fa-gear" label="Settings" to="/settings" exact />
      </q-tabs>

      <div style="position: absolute;  top: 8px; right: 16px;" title="Current Project">Project: {{ projectName }}</div>
      <a href="#" v-if="authStore.isLoggedIn" style="position: absolute;  top: 42px; right: 16px; color: white" title="Logout" @click.prevent="logout">{{  authStore.displayName }}</a>
    </q-header>
    <q-page-container>
      <router-view name="main"></router-view>
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useCategoryStore } from './stores/CategoryStore'
import { useAuthStore } from '@/stores/AuthStore'

const categoryStore = useCategoryStore()
const authStore = useAuthStore()
const projectName = ref('')

function logout() {
  authStore.logout()
}

onMounted(async () => {
  try {
    const res2 = await axios.get('/api/projectName')
    projectName.value = res2.data
  } catch (error) {
    console.log(error)
  }
})

</script>
