import { defineStore } from 'pinia'
import axios from 'axios'
import { useAuthStore } from './AuthStore'
import { ref } from 'vue'

export const useCategoryStore = defineStore('category', () => {
  const tabs = ref([])
  const authStore = useAuthStore()

  async function initializeCategories() {
    if (!tabs.value.length) {
      console.trace('load Tabs')
      const res = await axios.get('/api/entityTypes', {
        headers: {
          Authorization: `Bearer ${authStore.getJWT()}`
        }
      })
      tabs.value = res.data
    }
  }
  return { tabs, initializeCategories }
})
