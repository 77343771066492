import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'

const routes = [
  {
    path: '/login',
    name: 'login',
    components: {
      main: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    components: {
      main: () => import(/* webpackChunkName: "changePassword" */ '../views/PasswordView.vue')
    }
  },
  {
    name: 'pages',
    path: '/page',
    components: {
      main: () => import(/* webpackChunkName: "pages" */ '../views/PagesView.vue')
    },
    props: true,
    children: [
      {
        name: 'page',
        path: ':id',
        components: {
          pageview: () => import(/* webpackChunkName: "page" */ '../views/PageView.vue')
        },
        props: true
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    components: {
      main: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
    }
  },
  {
    path: '/category/:id',
    name: 'category',
    components: {
      main: () => import(/* webpackChunkName: "category" */ '../views/CategoryView.vue')
    },
    props: true,
    children: [
      {
        name: 'template',
        path: 'template/:templateId',
        components: {
          inner: () => import(/* webpackChunkName: "template" */ '../views/TemplateView.vue')
        },
        props: true
      },
      {
        name: 'entity',
        path: 'entity/:entityId',
        components: {
          inner: () => import(/* webpackChunkName: "entity" */ '../views/EntityView.vue')
        },
        props: true
      },
      {
        name: 'folder',
        path: 'folder/:folderId',
        components: {
          inner: () => import(/* webpackChunkName: "folders" */ '../views/FoldersView.vue')
        },
        props: true
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/page'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  if (to.name === 'login' || to.name === 'changePassword') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (await authStore.getLoggedIn()) {
    next() // i'm logged in. carry on
  } else {
    next({ name: 'login' })
  }
})

export default router
